import React from "react"
import "./title.scss"

const Title = ({ title }) => {
    return (
        <div className="section__title">
            <h2>{title || "default title"}</h2>
            <div className="u-underline"></div>
        </div>
    )
}

export default Title
